import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  username: "",
  email: "",
  hash: "",
  agreeMarketingComms: false,
  agreeReminders: false,
  paddleCustomerID: "",
  paddleSubscriptionID: "",
  subscriptionStatus: "",
  subscriptionNextBilledAt: "",
  subscriptionScheduledChange: "",
  currentLanguage: "",
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginUser: (state, action) => {
      const {
        username, email, hash,
        agreeMarketingComms, agreeReminders,
        paddleCustomerID, paddleSubscriptionID,
        subscriptionStatus, subscriptionNextBilledAt, subscriptionScheduledChange,
        currentLanguage
      } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        username,
        email,
        hash,
        agreeMarketingComms,
        agreeReminders,
        paddleCustomerID,
        paddleSubscriptionID,
        subscriptionStatus,
        subscriptionNextBilledAt,
        subscriptionScheduledChange,
        currentLanguage,
      };
    },
    logoutUser: () => initialState,
    setCurrentLanguage: (state, action) => { 
      state.currentLanguage = action.payload;
    },
    setAgreeMarketingComms: (state, action) => {
      state.agreeMarketingComms = action.payload;
    },
    setAgreeReminders: (state, action) => {
      state.agreeReminders = action.payload;
    },
  },
});

export const { loginUser, logoutUser, setCurrentLanguage, setAgreeMarketingComms, setAgreeReminders } = userSlice.actions;
export default userSlice.reducer;
