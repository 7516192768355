import pageStyles from '../styles/page-common.module.css';

function Loading() {
  return (
    <div className={pageStyles.styledPage}>
      Loading...
    </div>
  );
}

export default Loading;