import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { stateSetCurrentLanguage } from '../store';
import { SUPPORTED_LANGUAGES } from '../constants';

//
// Dropdown menu to choose language for review. 
// Initial state is user's currentLanguage redux state.
// Changing updates user's currentLanguage in redux state AND on backend.
//
function LanguageDropdown({ className }) {
  
  // get user redux state
  const user = useSelector((state) => state.user);

  // current language
  const selectedLanguage = user.currentLanguage;

  // create dropdown options from SUPPORTED_LANGUAGES object
  const options = useMemo(() => {
    return Object.entries(SUPPORTED_LANGUAGES).map(([value, label]) => ({
      value,
      label,
    }));
  }, []);

  // react state for dropdown option
  const [selectedOption, setSelectedOption] = useState(options.find(option => option.value === selectedLanguage));
  
  // this effect is here to make sure pre-selected dropdown option is updated
  // when user is finished logging in
  useEffect(() => {
    setSelectedOption(options.find(option => option.value === selectedLanguage));
  }, [selectedLanguage, options])

  function handleChange(event) {
    // get selected language
    const selectedValue = event.target.value;
    
    // set react state
    const selected = options.find(option => option.value === selectedValue);
    setSelectedOption(selected);

    // update redux state and backend
    stateSetCurrentLanguage(selected.value);
  }

  return (
    <select name="readingLanguage" value={selectedOption?.value} onChange={handleChange} className={className}>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );

}

export default LanguageDropdown;