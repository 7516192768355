import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import pageStyles from '../styles/page-common.module.css';

function UnscheduleDeletionSuccess() {

  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      // Navigate home and reload the app
      navigate('/home');
      window.location.reload();
    }, 6000);

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, [navigate]);

  return (
    <div className={pageStyles.styledPage}>
      <h1>Account Deletion Unscheduled</h1>
      <p><strong>Please wait a few seconds</strong> for the app to reload and reflect the changes. You will automatically return home.</p>
    </div>
  );
}

export default UnscheduleDeletionSuccess;