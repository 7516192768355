import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LanguageDropdown from '../components/LanguageDropdown';
import Loading from '../components/Loading';
import TreeBox from '../components/TreeBox';
import pageStyles from '../styles/page-common.module.css';
import { getWeekIndex } from '../utils';
import { getTreeMetadata } from '../backend';


function Home() {
  const user = useSelector((state) => state.user);
  const streakState = useSelector((state) => state.streak);

  const [treeMetadata, setTreeMetadata] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const currentWeekIndex = getWeekIndex();

  // when page loads, download this week's tree
  useEffect(() => {
    async function loadTree() {
      setTreeMetadata(null);
      try {
        setLoading(true);
        // const response = await getTree(currentWeekIndex, user.username, user.currentLanguage);
        const response = await getTreeMetadata({
          username: user.username,
          week_indices: [currentWeekIndex],
          languages: [user.currentLanguage],
        });
        setTreeMetadata(response[0]);
        setError("");
      }
      catch(error) {
        if (error.response && error.response.status=== 404) {
          setError("This week's tree not found.")
        }
        else {
          setError("Some error occurred.")
        }
      }
      finally {
        setLoading(false);
      }
    }
    loadTree();
  }, [currentWeekIndex, user]);

  // if page still loading return "loading" component
  if (loading) return <Loading/>;

  return (
    <div className={pageStyles.styledPage}>
      <h1>Welcome, {user.username}!</h1>
      {user.subscriptionStatus === 'past_due' && (
        <div className={pageStyles.alertBanner}>
          <p>Your subscription renewal payments have failed. Your account will remain active for 30 days since the first failed payment. Please update your payment method <NavLink to='/update-payment-method'>here</NavLink>.</p>
        </div>
      )}
      {user.subscriptionStatus === 'paused' && (
        <div className={pageStyles.alertBanner}>
          <p>Your subscription is paused.</p>
        </div>
      )}
      {user.subscriptionStatus === 'trialing' && user.subscriptionNextBilledAt && (
        <div className={pageStyles.alertBanner}>
          <p>You are on a free trial. Your trial ends on <strong>{user.subscriptionNextBilledAt.slice(0, 10)}</strong>.</p>
        </div>
      )}
      {user.subscriptionScheduledChange && user.subscriptionScheduledChange.action === 'cancel' && (
        <div className={pageStyles.alertBanner}>
          <p>Your subscription is due to cancel on <strong>{user.subscriptionScheduledChange.effective_at.slice(0, 10)}</strong>. Your account will be deleted.<br/><NavLink to='/unschedule-deletion'>Unschedule deletion</NavLink></p>
        </div>
      )}
      {streakState.pending && streakState.streak !== 0 && (
        <div className={pageStyles.alertBanner}>
          <p>You have not yet collected a tree this week. To maintain your <NavLink to='/streak'>weekly streak</NavLink>, complete a tree!</p>
        </div>
      )}
      <p>Language:</p>
      <LanguageDropdown className={pageStyles.dropdown}/>
      <hr className={pageStyles.hrule} />
      {error 
        ?
        <div className={pageStyles.alertBanner}><p>{error}</p></div>
        :
        <>
          <h2>This Week's Tree: {treeMetadata.title}</h2>
          <TreeBox treeMetadata={treeMetadata} level='beg' showTitle={false} showLanguage={false}/>
          <TreeBox treeMetadata={treeMetadata} level='int' showTitle={false} showLanguage={false}/>
          <TreeBox treeMetadata={treeMetadata} level='adv' showTitle={false} showLanguage={false}/>
        </>
      }
      <hr className={pageStyles.hrule} />
      <p>Alternatively, visit your list of <NavLink to='/in-progress'>trees in progress</NavLink> or the <NavLink to='/archive'>tree archive</NavLink>.</p>
    </div>
  );
}

export default Home;