import { NavLink } from 'react-router-dom';
import pageStyles from '../styles/page-common.module.css';

function Future() {
  return (
    <div className={pageStyles.styledPageLeftAligned}>
      <h1>Future</h1>
      <p>This page is a non-exhaustive list of the languages and features we would like to add to LingoLeaf in the future. If there is something in particular you would like to see and it is not on this page, please <NavLink to='/contact'>get in touch</NavLink>!</p>
      
      <h2>Languages</h2>
      <ul>
        <li>Chinese</li>
        <li>Korean</li>
        <li>Arabic</li>
        <li>Turkish</li>
        <li>Italian</li>
        <li>Portuguese</li>
        <li>Russian</li>
      </ul>

      <h2>Other Features</h2>
      <ul>
        <li>Additional languages for translations</li>
        <li>Additional languages for site content</li>
        <li>Improved site accessibility</li>
        <li>Study reminder emails</li>
        <li>Subscription pauses</li>
        <li>Mobile app</li>
      </ul>
    </div>
  );
}

export default Future;