import { useSelector } from 'react-redux';
import pageStyles from '../styles/page-common.module.css';

function Streak() {
  const streakState = useSelector((state) => state.streak);

  let streakStatement;
  if (streakState.streak) {
    if(streakState.streak > 1) {
      streakStatement = <p>Your weekly streak is: <strong>{streakState.streak} weeks</strong></p>
    } else {
      streakStatement = <p>Your weekly streak is: <strong>1 week</strong></p>
    }
  } else {
    streakStatement = <p>You have not got a weekly streak yet. Collect a tree to start one!</p>
  }

  return (
    <div className={pageStyles.styledPage}>
      <h1>Your Weekly Streak</h1>
      {streakStatement}
      {streakState.pending && streakState.streak !== 0 && <p>You have not yet collected a tree this week. To maintain your streak, complete a tree!</p>}
      {!streakState.pending && <p>Keep up the good work!</p>}
    </div>
  );
}

export default Streak;