
// whether this is dev.lingoleaf.io
const IS_DEV_FRONTEND = window.location.href === 'https://dev.lingoleaf.io/' || window.location.href.startsWith('https://dev.lingoleaf.io')

const INITIAL_HINT_COUNT = 5;

const SUPPORTED_LANGUAGES = {
    'en': 'English',
    'de': 'German',
    'fr': 'French',
    'es': 'Spanish',
    'ja': 'Japanese',
    'hi': 'Hindi',
}
const LEVEL_NAMES = {
  'beg': 'Beginner',
  'int': 'Intermediate',
  'adv': 'Advanced',
}

// set PADDLE_LIVE to true to switch sandbox -> live
const PADDLE_LIVE = false
const PADDLE_SANDBOX_CLIENT_TOKEN = "test_771ed15bf5b411058ef171dd05c"
const PADDLE_LIVE_CLIENT_TOKEN = "live_7611d833c8cf1b6b135a5370555"
const PADDLE_SANDBOX_PRICE_ID = "pri_01jer1xmaq0jz0y6gee8jf1v1m"
const PADDLE_SANDBOX_PRICE_ID_NO_TRIAL = "pri_01jhmjm37mbk5na6wc43hn92bk"
const PADDLE_LIVE_PRICE_ID = "pri_01jer27ykgvnnym2m108v7hngs"
const PADDLE_LIVE_PRICE_ID_NO_TRIAL = "pri_01jhmjs7s71sgnqk104ymndhvw"
const PADDLE_PRICE_ID = PADDLE_LIVE ? PADDLE_LIVE_PRICE_ID : PADDLE_SANDBOX_PRICE_ID
const PADDLE_PRICE_ID_NO_TRIAL = PADDLE_LIVE ? PADDLE_LIVE_PRICE_ID_NO_TRIAL : PADDLE_SANDBOX_PRICE_ID_NO_TRIAL
const PADDLE_CLIENT_TOKEN = PADDLE_LIVE ? PADDLE_LIVE_CLIENT_TOKEN : PADDLE_SANDBOX_CLIENT_TOKEN

export { IS_DEV_FRONTEND, INITIAL_HINT_COUNT, SUPPORTED_LANGUAGES, LEVEL_NAMES, PADDLE_LIVE, PADDLE_PRICE_ID, PADDLE_PRICE_ID_NO_TRIAL,PADDLE_CLIENT_TOKEN }