import { useEffect } from 'react';
import { Route, Routes,  Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkToken } from './backend';
import { login } from './authentication';
import { IS_DEV_FRONTEND } from './constants';
import appStyles from './styles/app.module.css';
import Home from './pages/Home';
import About from './pages/About';
import AccountDeletion from "./pages/AccountDeletion";
import AccountDeletionSuccess from './pages/AccountDeletionSuccess';
import AccountDeletionScheduled from './pages/AccountDeletionScheduled';
import Archive from './pages/Archive';
import Charity from './pages/Charity';
import CompletedTrees from './pages/CompletedTrees';
import Contact from './pages/Contact';
import DefaultPayment from './pages/DefaultPayment';
import FAQs from './pages/FAQs';
import Future from './pages/Future';
import InProgress from './pages/InProgress';
import Landing from './pages/Landing';
import Languages from './pages/Languages';
import Leaf from './pages/Leaf';
import LeafComplete from './pages/LeafComplete';
import Login from './pages/Login';
import News from './pages/News';
import NotFound from './pages/NotFound';
import Privacy from './pages/Privacy';
import Pricing from './pages/Pricing';
import Register from "./pages/Register";
import ResendConfirmation from './pages/ResendConfirmation';
import RegistrationSuccess from "./pages/RegistrationSuccess";
import Settings from './pages/Settings';
import Streak from './pages/Streak';
import Terms from './pages/Terms';
import ForgottenUsername from './pages/ForgottenUsername';
import ForgottenPassword from './pages/ForgottenPassword';
import PasswordReset from './pages/PasswordReset';
import ConfirmEmail from './pages/ConfirmEmail';
import Logout from "./pages/Logout";
import Tree from './pages/Tree';
import HeaderBar from "./components/HeaderBar";
import FooterBar from "./components/FooterBar";
import UpdatePaymentMethod from './pages/UpdatePaymentMethod';
import UnscheduleDeletion from './pages/UnscheduleDeletion';
import UnscheduleDeletionSuccess from './pages/UnscheduleDeletionSuccess';


function StandardPageLayout() {
  return (
    <div className={appStyles.appContainer}>
      <HeaderBar />
      <main>
        <div className={appStyles.appMargin}></div>
        <div className={appStyles.appCentre}>
          {IS_DEV_FRONTEND && <div className={appStyles.alertBanner}><p>WARNING: This is the development version of LingoLeaf. You won't be able to log in unless you work here. Please visit the <a href="https://lingoleaf.io" rel="noopener noreferrer">real LingoLeaf</a> instead.</p></div>}
          <Outlet />
        </div>
        <div className={appStyles.appMargin}></div>
      </main>
      <FooterBar />
    </div>
  );
}


function LoggedInRoutes({userInLocalStorage, isLoggedIn}) {
  return (
    userInLocalStorage ?
    <>
      {isLoggedIn && <Outlet/>}
    </>
    :
    <Navigate to='/logout'/>
  );
}


function ActiveSubscriptionRoutes({ userInLocalStorage, isLoggedIn }) {
  const user = useSelector((state) => state.user);

  return (
    userInLocalStorage && user.subscriptionStatus !== 'paused' ?
    <>
      {isLoggedIn && <Outlet/>}
    </>
    :
    <Navigate to='/'/>
  );
}


function App() {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.user).isLoggedIn;

  // when app loads up, if user in local storage, check token and relogin
  useEffect(() => {
    async function attemptRelogin() {
      const tokenValid = await checkToken(localStorage.getItem('username'))
      if(tokenValid) {
        login(localStorage.getItem('username'))
      } else {
        localStorage.removeItem('username')
        navigate('/logout')
      }
    }
    if(localStorage.getItem('username') && !isLoggedIn) {
      attemptRelogin();
    }
  }, [isLoggedIn, navigate]);

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route element={<StandardPageLayout />}>
        <Route element={<LoggedInRoutes userInLocalStorage={localStorage.getItem('username')} isLoggedIn={isLoggedIn}/>}>
          <Route path="/home" element={<Home/>} />
          <Route path="/archive" element={<Archive/>} />
          <Route path="/completed-trees" element={<CompletedTrees/>} />
          <Route path="/in-progress" element={<InProgress/>} />
          <Route path="/settings" element={<Settings/>} />
          <Route path="/account-deletion" element={<AccountDeletion/>} />
          <Route path="/account-deletion-scheduled" element={<AccountDeletionScheduled/>} />
          <Route path="/streak" element={<Streak/>} />
          <Route path="/unschedule-deletion" element={<UnscheduleDeletion/>} />
          <Route path="/unschedule-deletion-success" element={<UnscheduleDeletionSuccess/>} />
          <Route path="/update-payment-method" element={<UpdatePaymentMethod/>} />
          <Route element={<ActiveSubscriptionRoutes userInLocalStorage={localStorage.getItem('username')} isLoggedIn={isLoggedIn}/>}>
            <Route path="/tree" element={<Tree/>} />
            <Route path="/leaf" element={<Leaf/>} />
            <Route path="/leaf-complete" element={<LeafComplete/>} />
          </Route>
        </Route>
        <Route path="/about" element={<About/>}/>
        <Route path="/charity" element={<Charity/>}/>
        <Route path="/default-payment" element={<DefaultPayment/>}/>
        <Route path="/faqs" element={<FAQs/>}/>
        <Route path="/future" element={<Future/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/privacy" element={<Privacy/>}/>
        <Route path="/news" element={<News/>}/>
        <Route path="/terms" element={<Terms/>}/>
        <Route path="/pricing" element={<Pricing/>}/>
        <Route path="/languages" element={<Languages/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/logout" element={<Logout/>} />
        <Route path="/register" element={<Register/>} />
        <Route path="/resend-confirmation" element={<ResendConfirmation/>} />
        <Route path="/forgotten-username" element={<ForgottenUsername/>} />
        <Route path="/forgotten-password" element={<ForgottenPassword/>} />
        <Route path="/reset-password/:token" element={<PasswordReset/>} />
        <Route path="/registration-success" element={<RegistrationSuccess/>} />
        <Route path="/account-deletion-success" element={<AccountDeletionSuccess/>} />
        <Route path="/confirm/:token" element={<ConfirmEmail/>} />
        <Route path="*" element={<NotFound/>} />
      </Route>
    </Routes>
  );
}

export default App;
