import pageStyles from '../styles/page-common.module.css';


function Charity() {
  return (
    <div className={pageStyles.styledPageLeftAligned}>
      <h1>Plant Trees!</h1>
      <p>
        Trees are essential for life. They provide oxygen, stabilise
        soil, and support wildlife. By drawing down carbon dioxide, they also play a crucial role in the fight against climate change.
        We're very keen to make sure LingoLeaf has a net positive impact on the environment, so we
        pledge to donate <strong>30% of our profits</strong> to tree planting
        and conservation efforts. This is split equally across two charities:
      </p>

      <ul style={{ lineHeight: "1.8" }}>
        <li>
          <a style={{fontWeight: "600"}} href="https://treesforlife.org.uk" target="_blank" rel="noopener noreferrer">Trees for Life</a>: Rewilding the Scottish Highlands
          by planting native trees and restoring degraded landscapes.
        </li>
        <li>
          <a style={{fontWeight: "600"}} href="https://internationaltreefoundation.org" target="_blank" rel="noopener noreferrer">International Tree Foundation</a>: Supporting
          tree planting and conservation projects worldwide, including community-led
          initiatives.
        </li>
      </ul>

      <p>
        By studing with LingoLeaf, you're directly contributing to these efforts, and helping to make the world an ever so slightly leafier place.
      </p>
    </div>
  );
};

export default Charity;