import pageStyles from '../styles/page-common.module.css';

function News() {
  return (
    <div className={pageStyles.styledPageLeftAligned}>
      <h1>News</h1>
      <p>No news to report, but we're hoping to launch soon!</p>
    </div>
  );
}

export default News;