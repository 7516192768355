import { NavLink } from 'react-router-dom';
import pageStyles from '../styles/page-common.module.css';

function Privacy() {
  return (
    <div className={pageStyles.styledPageLeftAligned}>
      <h1>Privacy Policy</h1>
        <p><strong>Effective Date: February 1, 2025</strong> </p>
        <p><strong>Last Updated: January 20, 2025</strong> </p>
      
        <p>At LingoLeaf, we are committed to protecting and respecting your privacy. This Privacy Policy outlines how we collect, use, store, and protect your personal data in accordance with UK and EU General Data Protection Regulation (GDPR) and other relevant privacy laws. Please read this policy carefully to understand how we handle your information.</p>
        
        <h2>1. Information We Collect</h2>
          <p>We only keep information on users who have registered for an account on the Service. We do not collect any sensitive personal information, and we do not track or monitor user behavior for advertising or analytics purposes. </p>
          <p>We keep the following information on our secure database (hosted by MongoDB):</p>
          <ul>
            <li>Email address</li>
            <li>Password (stored in a secure hashed format; see below)</li>
            <li>Additionally data essential to functionality of the Service, such as your language level, daily streak, and progress within the Service.</li>
          </ul>
          <p>Our subscription payments are managed by Paddle. Sensitive payment information such as credit card numbers are invisible to us. Please familiarise yourself with their <a href='https://www.paddle.com/legal/checkout-buyer-terms' rel="noreferrer" target="_blank">terms of use</a> and <a href='https://www.paddle.com/legal/privacy' rel="noreferrer" target="_blank">privacy policy</a>.</p>
          <p>See Third-Party Services below for more information about both MongoDB and Paddle.</p>
  
        <h2>2. How We Use Your Data</h2>
          <p>The data we collect is used for the following purposes:</p>
          <ul>
            <li><strong>Account Management:</strong> To enable you to create an account, log in, and use the Service.</li>
            <li><strong>Subscription payments:</strong> We use Paddle, a third-party payment processor, to handle all payments and billing information.</li>
            <li><strong>Functionality:</strong> To track your language-learning progress and personalize the content provided based on your language level.</li>
            <li><strong>Communication:</strong> To send you essential information about your account, including password recovery or important updates. Additionally, you can opt into marketing communications with offers and promotions, and reminder communications reminding you to use the Service. After having opted in, you can opt out of marketing and reminder communications via the "Settings" interface.</li>
          </ul>
          <p>We do not share your personal information with third parties for marketing or any other purposes.</p>

        <h2>3. Cookies and Tracking</h2>
          <p>We use cookies only to manage authentication, keep you logged in, and ensure the security of your interactions within the Service. Specifically, we use:</p>
          <ul>
            <li><strong>JWT (JSON Web Tokens):</strong> For authentication and session management.</li>
            <li><strong>CSRF (Cross-Site Request Forgery) Access Tokens:</strong> For preventing cross-site request forgery in JWT-based authentication.</li>
          </ul>
          <p>When you log in, these cookies are issued to keep you logged in for up to <strong>60 hours</strong>. After 60 hours, your token expires, and you’ll need to log in again. If you use the Service during the last 30 hours of the token's validity, the cookies will refresh, extending the session for another 60 hours.</p>
          <p>These cookies are essential for the functioning of the Service and are not used for tracking or analytics purposes.</p>
          <p>By registering for an account on the Service, you consent to our use of cookies as described above.</p>
      
        <h2>4. Third-Party Services</h2>
          <p>We use third-party services to provide certain functionalities within the Service. These third-party providers are only given access to your personal data where necessary to perform their services and are obligated to protect your information.</p>
          <ul>
              <li><strong>Payment Processing:</strong> We use <strong>Paddle</strong> to process subscription payments. When you provide payment information, such as credit card details, this information is transmitted directly to Paddle. We do not store or have access to your payment information, but Paddle processes it securely in compliance with the Payment Card Industry Data Security Standards (PCI-DSS). For more information on how Paddle handles your data, you can review their Privacy Policy <a href='https://www.paddle.com/legal/privacy' rel="noreferrer" target="_blank">here</a>.</li>
              <li><strong>Data Storage:</strong> We use <strong>MongoDB</strong> to store your personal information, including email addresses, hashed passwords, and language progression data. Our MongoDB database is hosted in Ireland, within the European Economic Area (EEA), ensuring GDPR compliance. MongoDB provides secure storage services and is obligated to protect your data according to industry-standard practices. For more information, you can review MongoDB's Privacy Policy <a href="https://www.mongodb.com/legal/privacy-policy" rel="noreferrer" target="_blank">here</a>.</li>
          </ul>
          <p></p>

        <h2>5. Data Storage and Security</h2>
          <p>We store your data in a secure MongoDB database located in Ireland. This means that your personal information is processed and stored within the European Economic Area (EEA), ensuring compliance with UK and EU GDPR regulations. This does not include your payment data, which is managed by Paddle.</p>
          <p>The data stored includes your email address, password (in hashed format), and app-related data such as your language progression. We take industry-standard security measures to protect your personal information, including:</p>
          <ul>
              <li><strong>Password Hashing:</strong> Your password is stored in a hashed format to prevent unauthorized access.</li>
              <li><strong>Encryption:</strong> Sensitive data is encrypted during transmission using secure protocols.</li>
              <li><strong>Access Control:</strong> Access to personal data is restricted to authorized personnel only, and we regularly review our data security practices to ensure the highest level of protection.</li>
          </ul>
          <p>We do not transfer your personal data outside of the EEA. Should we need to transfer data in the future, we will take appropriate measures to ensure that your data is protected in accordance with applicable laws.</p>
          <p>Despite our best efforts, no method of data transmission or storage is completely secure, and we cannot guarantee the absolute security of your information.</p>
      
        <h2>6. Lawful Basis for Processing Personal Data</h2>
          <p>Under the GDPR, we rely on the following lawful bases for processing your personal data:</p>
          <ul>
              <li><strong>Contractual Necessity:</strong> We process your personal data, including your email address, language progression data, and hashed password, to provide the services outlined in our Terms of Service. This includes managing your account, delivering the Service's functionality, and processing your subscription payments.</li>
              <li><strong>Legitimate Interests:</strong> We process certain data to improve the Service's user experience and functionality, such as tracking your language progression and customizing content to your language level. These activities are necessary for the ongoing operation and improvement of the Service, and we ensure that your rights and interests are protected.</li>
              <li><strong>Legal Obligations:</strong> We may process personal data to comply with legal requirements, such as financial reporting and tax obligations related to your subscription payments.</li>
          </ul>
          <p>Where applicable, we will obtain your explicit consent for any data processing that does not fall under the above lawful bases. You can withdraw your consent at any time by contacting us (see "Contact Us" below).</p>

        <h2>7. Your Rights Under GDPR</h2>
          <p>As a user of the Service, you have the following rights under the GDPR:</p>
          <ul>
              <li><strong>Access:</strong> You have the right to request access to the personal data we hold about you.</li>
              <li><strong>Rectification:</strong> You have the right to request that we correct any inaccurate or incomplete data.</li>
              <li><strong>Erasure:</strong> You have the right to request the deletion of your personal data (also known as the "right to be forgotten").</li>
              <li><strong>Restriction:</strong> You have the right to request that we limit the processing of your data in certain situations.</li>
              <li><strong>Portability:</strong> You have the right to request a copy of your data in a machine-readable format.</li>
              <li><strong>Objection:</strong> You have the right to object to the processing of your data in certain circumstances.</li>
          </ul>
          <p>To exercise any of these rights, please contact us (see "Contact Us" below).</p>
      
        <h2>8. Data Retention</h2>
          <p>We will retain your personal data for as long as your account is active or as necessary to comply with legal requirements. If you choose to delete your account or request erasure of your data, we will remove your data from our systems in accordance with applicable legal requirements.</p>
      
        <h2>9. Changes to This Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time. If we make significant changes, we will notify you by email or through the website interface. Your continued use of the Service after any changes indicates your acceptance of the updated Privacy Policy.</p>
      
        <h2>10. Contact Us</h2>
          <p>If you have any questions or concerns about this Privacy Policy or how your data is handled, please contact us via the <NavLink to='/contact'>Contact page</NavLink> of the site.</p>
    </div>
  );
}

export default Privacy;