import { useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { initializePaddle, getPaddleInstance } from '@paddle/paddle-js';
import axios from 'axios';
import pageStyles from '../styles/page-common.module.css';
import formStyles from '../styles/form-common.module.css';
import { createUser, checkRegDetails } from '../backend';
import { PADDLE_LIVE, PADDLE_PRICE_ID, PADDLE_PRICE_ID_NO_TRIAL, PADDLE_CLIENT_TOKEN } from '../constants';


const Register = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Get the navigate function
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const [agreeMarketingComms, setAgreeMarketingComms] = useState(false);
  const [agreeReminders, setAgreeReminders] = useState(false);
  const isLoggedIn = useSelector((state) => state.user).isLoggedIn;
  const [paddle, setPaddle] = useState();
  const [userCreated, setUserCreated] = useState(false);
  const [checkoutComplete, setCheckoutComplete] = useState(false);
  const [customerID, setCustomerID] = useState("");
  const [ip, setIp] = useState('');
  const [priceString, setPriceString] = useState('[estimating price]');
  const [warnedForNoTrial, setWarnedForNoTrial] = useState(false); // New state

  // get customer IP address on mount
  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIp(response.data.ip);
      } catch (error) {
        console.error('Error fetching IP:', error);
      }
    };

    fetchIp();
  }, []);

  // shouldn't be here if logged in; navigate to home
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/home');
    }
  }, [navigate, isLoggedIn]);


  // download and initialize Paddle instance from CDN
  useEffect(() => {
    
    // config settings for Paddle
    const paddleConfig = {
      token: PADDLE_CLIENT_TOKEN,
      eventCallback: async function(data) {
        if (data.name === "checkout.customer.created") {
          setCustomerID(data.data.customer.id);
        }
        else if (data.name === "checkout.completed") {
          setCheckoutComplete(true);
        } else if (data.name === "checkout.closed") {
          setLoading(false);
          setCustomerID("");
        }
      }
    };

    // add environment key only if not in production mode
    if (!PADDLE_LIVE) {
      paddleConfig.environment = 'sandbox';
    }

    // initialise Paddle
    initializePaddle(paddleConfig).then(
      paddleInstance => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      },
    );
  }, []);

  // use IP address to personalise price
  useEffect(() => {
    if (ip && paddle) {
      const priceConfig = {
        items: [{ priceId: PADDLE_PRICE_ID, quantity: 1 }],
        customerIpAddress: ip,
      }
      paddle.PricePreview(priceConfig)
      .then((result) => {
        setPriceString(`${result.data.details.lineItems[0].formattedTotals.total} (${result.data.currencyCode})`)
      })
      .catch((error) => {
        console.error(error);
      });
    }
  }, [paddle, ip])

  // when checkout complete create user
  useEffect(() => {
    if (checkoutComplete) {
      const createUserAsync = async () => {
        try {
          setErrorMessage('');
          const userData = {
            'email': formData.email,
            'username': formData.username,
            'password': formData.password,
            'paddleCustomerID': customerID,
            'agreeTerms': agreeTerms,
            'agreePrivacy': agreePrivacy,
            'agreeMarketingComms': agreeMarketingComms,
            'agreeReminders': agreeReminders,
          }
          const response = await createUser(userData);
          if(response.status === 201) {
            setUserCreated(true);
          }
        } catch (error) {
          console.log(error);
          closeCheckout();
          setErrorMessage('Something went wrong.');
          setLoading(false);
        }
      }
      createUserAsync()
    }    
  }, [formData, customerID, agreeTerms, agreePrivacy, agreeMarketingComms, agreeReminders, checkoutComplete, navigate]);

  // when user created, close checkout window, navigate to success page
  useEffect(() => {
    if (userCreated) {
      closeCheckout()
      navigate('/registration-success');
    }    
  }, [userCreated, navigate]);

  // callback to close checkout
  const closeCheckout = () => {
    const p = getPaddleInstance()
    p.Checkout.close();
  }

  // callback to open checkout
  const openCheckout = (freeTrial) => {
    const priceId = freeTrial ? PADDLE_PRICE_ID : PADDLE_PRICE_ID_NO_TRIAL
    paddle?.Checkout.open({
      items: [{ priceId: priceId, quantity: 1 }],
      customer: {email: formData.email},
      settings: {allowLogout: false, showAddDiscounts: false},
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWarnedForNoTrial(false);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (name === 'agreeTerms') setAgreeTerms(checked);
    if (name === 'agreePrivacy') setAgreePrivacy(checked);
    if (name === 'agreeMarketingComms') setAgreeMarketingComms(checked);
    if (name === 'agreeReminders') setAgreeReminders(checked);
  };

  // Username validation: alphanumeric and < 30 characters
  const isUsernameValid = (username) => {
    const usernameRegex = /^[a-zA-Z0-9]+$/; // only letters and numbers
    return username.length <= 30 && usernameRegex.test(username);
  };

  // Password validation: min 8 chars, mix of letters, numbers, special chars
  const isPasswordStrong = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    return passwordRegex.test(password);
  };

  const handleRegistration = async (e) => {
    e.preventDefault();

    // check if passwords match
    if (formData.password !== formData.confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    // validate username
    if (!isUsernameValid(formData.username)) {
      setErrorMessage('Username must be alphanumeric and less than 30 characters');
      return;
    }

    // validate password strength
    if (!isPasswordStrong(formData.password)) {
      setErrorMessage('Password must be at least 8 characters long, with uppercase, lowercase, numbers, and special characters.');
      return;
    }

    setLoading(true);
    try {
      setErrorMessage('');
      const response = await checkRegDetails(formData)
      if(response.status === 200) {
        const { past_subscription } = response.data;
        if (past_subscription && !warnedForNoTrial) {
          setWarnedForNoTrial(true);
          setLoading(false);
        } else {
          openCheckout(!past_subscription);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage('Username or email already in use. Please choose a different one.');
        setLoading(false);
      } else {
        console.log(error);
        setErrorMessage('Something went wrong.');
        setLoading(false);
      }
    }
  };

  return (
    <div className={pageStyles.styledPage}>
      <h1>Register</h1>
      <p><strong>2-week free trial</strong>, then {priceString} per month.*</p>
      <p>Cancel any time.</p>
      <p>Already have an account? Sign in <NavLink to="/login">here</NavLink>.</p>
      <p>If you've registered for an account but need a new confirmation email, get one <NavLink to="/resend-confirmation">here</NavLink>.</p>
      <form onSubmit={handleRegistration} className={formStyles.styledForm}>
        <label>
          Username:
          <input
            type="text"
            name="username"
            placeholder="Username"
            value={formData.username}
            onChange={handleInputChange}
            autoComplete="username"
            required
          />
        </label>
        <label>
          Email:
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleInputChange}
            autoComplete="email"
            required
          />
        </label>
        <label>
          Password:
          <input
            type="password"
            name="password"
            placeholder="Password" 
            value={formData.password}
            onChange={handleInputChange}
            autoComplete="new-password"
            required
          />
        </label>
        <label>
          Confirm Password:
          <input
            type="password"
            name="confirmPassword"
            placeholder="Password" 
            value={formData.confirmPassword}
            onChange={handleInputChange}
            autoComplete="new-password"
            required
          />
        </label>
        <div className={formStyles.checkboxContainer}>
          <label>
            <input
              type="checkbox"
              name="agreeTerms"
              checked={agreeTerms}
              onChange={handleCheckboxChange}
            />
            I agree to the <NavLink to="/terms" target="_blank" rel="noopener noreferrer">Terms and Conditions</NavLink>.
          </label>
          <label>
              <input
                type="checkbox"
                name="agreePrivacy"
                checked={agreePrivacy}
                onChange={handleCheckboxChange}
              />
              I agree to the <NavLink to="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</NavLink> (including essential communications and "keep me logged in" cookies).
          </label>
          <label>
              <input
                type="checkbox"
                name="agreeMarketingComms"
                checked={agreeMarketingComms}
                onChange={handleCheckboxChange}
              />
              (OPTIONAL) I would like communications about special promotions. Opt out any time.
          </label>
          <label>
              <input
                type="checkbox"
                name="agreeReminders"
                checked={agreeReminders}
                onChange={handleCheckboxChange}
              />
              (OPTIONAL) I would like to receive reminders to use LingoLeaf. Opt out any time.
          </label>
        </div>
        <button type="submit" disabled={loading || !agreeTerms || !agreePrivacy}>
          {loading ? 'Registering...' : warnedForNoTrial ? 'Proceed' : 'Register'}
        </button>
      </form>
      {warnedForNoTrial && <div className={pageStyles.alertBanner}><p>You have had a subscription before and so are not eligible for a free trial. Press Proceed to confirm you would like to go ahead and set up a new subscription.</p></div>}
      {errorMessage && <div className={pageStyles.alertBanner}><p>{errorMessage}</p></div>}
      <p><small>*Price varies depending where in the world you are based. This quote is based on where you are currently accessing the site from, and might update when you enter your payment details at checkout, if your billing country is different from your current location. Users who have previously had subscriptions are not eligible for a free trial. See <NavLink to='/pricing'>Pricing</NavLink> page for further details.</small></p>
    </div>
  );
};

export default Register;