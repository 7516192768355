import { useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import landingStyles from '../styles/landing.module.css';
import {ReactComponent as IconLogo} from '../icons/logo_icontext.svg';
import FooterBar from '../components/FooterBar';
import { IS_DEV_FRONTEND } from '../constants';


const EXAMPLE_SENTENCE = "Un jour, Victor reçoit une mission mystérieuse."
const EXAMPLE_TRANSLATION = ["One day, Victor receives a mysterious mission."]
const EXAMPLE_BREAKDOWN = {
  "jour": "day; masculine noun",
  "reçoit": "receives; verb conjugated from recevoir (to receive)",
  "mission": "mission; feminine noun",
  "mystérieuse": "mysterious; adjective agreeing with singular feminine noun ('mission')"
}
const REMAINDER = [
  "Cette mission est spéciale. Elle se passe à bord du célèbre train, Le Grand Express. Ce train est très luxueux. Les passagers sont riches et importants. Victor est excité. Il aime le luxe et il aime les défis. Il prépare ses affaires pour le voyage. Il met son costume noir, sa chemise blanche et sa cravate rouge. Tout doit être parfait."
]


function LandingHeaderBar() {

  return (
    <header className={landingStyles.header}>
      <div className={landingStyles.headerLeft}>
        <IconLogo className={landingStyles.iconLogo}/>
      </div>
      <div className={landingStyles.headerMiddle}>
      </div>
      <div className={landingStyles.headerRight}>
        <NavLink to="/login">Sign In</NavLink>
        <NavLink to="/register" style={{display: 'contents'}}><button>Free Trial</button></NavLink>
      </div>
    </header>
  );
}

function AlertContainer() {
  return (
    <div className={landingStyles.alertContainer}>
      <div className={landingStyles.alertBanner}><p>WARNING: LingoLeaf is still in development. We're hoping to launch in February 2025.</p></div>
      {IS_DEV_FRONTEND && <div className={landingStyles.alertBanner}><p>WARNING: This is the development version of LingoLeaf. You won't be able to log in unless you work here. Please visit the <a href="https://lingoleaf.io" rel="noopener noreferrer">real LingoLeaf</a> instead.</p></div>}
    </div>
  )
}


function LandingExampleContainer() {

  return (
    <div className={landingStyles.exampleContainer}>
      <p className={landingStyles.disclaimer}>Example preview. Features and designs may change in the live version of the app.</p>
      <p><strong>{EXAMPLE_SENTENCE}</strong> {REMAINDER}</p>
      <div className={landingStyles.explanationBlock}>
        <p><strong>Translation:</strong></p>
        <p>{EXAMPLE_TRANSLATION}</p>
        <p><strong>Breakdown:</strong></p>
        <ul>
          {Object.entries(EXAMPLE_BREAKDOWN).map(([key, value]) => (
            <li key={key}>
              <strong>{key}: </strong>{value}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}


function Landing() {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.user).isLoggedIn;

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/home');
    }
  }, [navigate, isLoggedIn]);

  return (
    <div className={landingStyles.container}>
      <LandingHeaderBar/>
      <main className={landingStyles.main}>
        <AlertContainer/>
        <div className={landingStyles.contentContainer}>
          <div className={landingStyles.textContainer}>
            <p><span className={landingStyles.banner}>Learn to read a new language.</span></p>
            <br/>
            <ul>
              <li><strong>Read short stories</strong> with annotations, translations, and comprehension questions <strong>in your language.</strong></li>
              <li><strong>New stories every week.</strong></li>
              <li><strong>Many supported languages.</strong></li>
              <li><strong>Plant trees!</strong> 30% of LingoLeaf's profits go to tree-planting and conservation efforts.</li>
              <li><NavLink to='/faqs'><strong>Find out more</strong></NavLink> or <NavLink to='/register'><strong>sign up for a free trial</strong></NavLink></li>
            </ul>
          </div>
          <LandingExampleContainer/>
        </div>
      </main>
      <FooterBar />
    </div>
  );
}

export default Landing;