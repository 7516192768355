
/*
Function to get "week index" of date specified by dateArg (e.g. ISO string). 
If no dateArg provided, uses today's date for *current* week index.
Weeks are counted since the reference week (week 0) beginning Monday 1 January 2024.
*/
function getWeekIndex(dateArg=undefined) {
  // reference date: Monday 1 January 2024
  // (NB zero-indexed months)
  const referenceDate = new Date(2024, 0, 1);
  
  // if arg provided, feed to Date, otherwise no arg (today's date)
  let date
  if (dateArg) {
    date = new Date(dateArg);
  } else {
    date = new Date();
  }

  // get difference in time, convert to number of weeks
  const diffInWeeks = Math.floor((date - referenceDate) / (1000 * 60 * 60 * 24 * 7));
  return diffInWeeks;
}


export { getWeekIndex };