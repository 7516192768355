import { createSlice } from '@reduxjs/toolkit';
import { getWeekIndex } from '../utils';


function calculateStreak(completionWeeks) {
  
  const thisWeek = getWeekIndex()
  
  // initialise streak and pending values, will update in loop
  let streak = 0;
  let pending = true;

  // if no completions, return as is
  if (completionWeeks.length === 0) {
    return {streak, pending}
  }

  // get indices thisWeek - completionWeeks, then uniquify and sort
  let inds  = completionWeeks.map(element => thisWeek - element)
  inds = [...new Set(inds)].sort((a, b) => a - b);

  // if indices contain 0 (this week), streak increments by 1 and not pending
  if (inds.includes(0)) {
    streak = 1;
    pending = false;
  }

  // while loop, work up through indices (back through past weeks) to get streak
  let index = 1;
  let searching = true;
  while(searching) {
    if (inds.includes(index)) {
      streak += 1;
      index += 1;
    } else {
      searching = false;
    }
  }

  return {streak, pending};
}


const initialState = {
  streakLoaded: false,
  completionWeeks: [],
  streak: 0,
  pending: false,
};

const streakSlice = createSlice({
  name: 'streak',
  initialState,
  reducers: {
    addCompletionWeek: (state, action) => {
      const { completionWeek } = action.payload;
      const newWeeks = [...state.completionWeeks, completionWeek]
      const { streak, pending } = calculateStreak(newWeeks);
      return {
        ...state,
        'completionWeeks': newWeeks,
        streak,
        pending,
      };
    },
    loginStreak: (state, action) => {
      const { completionWeeks } = action.payload;
      const { streak, pending } = calculateStreak(completionWeeks);
      return {
        ...state,
        streakLoaded: true,
        completionWeeks,
        streak,
        pending,
      };
    },
    logoutStreak: () => initialState, 
  },
});

export const { addCompletionWeek, loginStreak, logoutStreak } = streakSlice.actions;
export default streakSlice.reducer;
