import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import pageStyles from '../styles/page-common.module.css';
import { deleteUser, scheduleUserDeletion } from '../backend';

function AccountDeletion() {

  const [queryError, setQueryError] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  // if user has upcoming cancellation scheduled, navigate to home
  useEffect(() => {
    if (user.subscriptionScheduledChange && user.subscriptionScheduledChange.action === 'cancel') {
      navigate('/home');
    }
  }, [navigate, user]);

  const handleDeleteNow = async () => {
    try {
      const response = await deleteUser(user.username);
      if(response.status === 200) {
        navigate('/account-deletion-success');
      }
    } catch (error) {
      console.error('Error deleting account:', error);
      setQueryError(true)
    }
  };

  const handleDeleteLater = async () => {
    try {
      const response = await scheduleUserDeletion(user.username);
      if(response.status === 200) {
        navigate('/account-deletion-scheduled');
      }
    } catch (error) {
      console.error('Error deleting account:', error);
      setQueryError(true)
    }
  };
  
  return (
    <div className={pageStyles.styledPage}>
      <h1>Account Deletion</h1>
      <h2>Are you sure you want to delete your account?</h2>
      <p>Note: this will also cancel your payment subscription. You can choose to either delete your account immediately, or delete your account at the end of your current billing period: {user.subscriptionNextBilledAt && user.subscriptionNextBilledAt.slice(0, 10)}.</p>
      {queryError && <div className={pageStyles.alertBanner}> <p>An error occurred while attempting to delete your account. Please try again later.</p></div>}
      <button onClick={handleDeleteNow}>Yes, immediately</button>
      <button onClick={handleDeleteLater}>Yes, on {user.subscriptionNextBilledAt && user.subscriptionNextBilledAt.slice(0, 10)} </button>
      <Link to="/settings" style={{display: 'contents'}}><button>No, return to settings</button></Link>
    </div>
  );
}

export default AccountDeletion;