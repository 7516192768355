import pageStyles from '../styles/page-common.module.css';
import { SUPPORTED_LANGUAGES } from '../constants';


function Languages() {
  return (
    <div className={pageStyles.styledPageLeftAligned}>
      <h1>Languages</h1>
      <p>Below is a list of our supported 'reading languages' (the languages used for the reading material, i.e., the language you want to learn) and a list of our supported 'teaching languages' (the languages used for the translations and explanations of the reading material, i.e., a language you are already proficient in).</p>
      <p> For example, if you are a native French speaker wanting to learn Japanese, you might choose French as your teaching language and Japanese as your reading language.</p> 
      <p>We're planning to add more languages in future, so watch this space!</p>
      <h2>Reading languages</h2>
      <ul>
        {Object.values(SUPPORTED_LANGUAGES).map((value, index)=><li key={index}><strong>{value}</strong></li>)}
      </ul>
      <h2>Teaching languages</h2>
      <ul>
        {Object.values(SUPPORTED_LANGUAGES).map((value, index)=><li key={index}><strong>{value}</strong></li>)}
      </ul>
    </div>
  );
}

export default Languages;