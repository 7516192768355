import { useEffect, useState } from 'react';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import pageStyles from '../styles/page-common.module.css';
import { confirmUser } from '../backend';


function ConfirmEmail() {

  // navigate hook
  const navigate = useNavigate();

  // get token from URL
  const { token } = useParams();

  // states
  const [confirmationSuccess, setConfirmationSuccess] = useState(0)
  const [message, setMessage] = useState('Click the button below to confirm your email.');
  const [processing, setProcessing] = useState(false);

  // whether user logged in
  const isLoggedIn = useSelector((state) => state.user).isLoggedIn;

  // shouldn't be here if logged in; navigate to home
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/home');
    }
  }, [navigate, isLoggedIn]);


  const handleConfirmClick = async () => {
    setProcessing(true);
    setMessage('Confirming...');
    try {
      await confirmUser(token);
      setMessage('Confirmation successful! Please sign in below.');
      setConfirmationSuccess(1);
    } catch (error) {
      setMessage('Confirmation failed. Perhaps the link is invalid or expired. Please try again or resend confirmation email below.');
      setConfirmationSuccess(-1);
    } finally {
      setProcessing(false);
    }
  };


  // useEffect(() => {
  //   const confirm = async () => {
  //     try {
  //       await confirmUser(token);
  //       setMessage('Confirmation successful! Please sign in below.');
  //       setConfirmationSuccess(1)
  //     } catch (error) {
  //       setMessage('Confirmation failed. Perhaps the link is invalid or expired. Please try again or resend confirmation email below.');
  //       setConfirmationSuccess(-1)
  //     }
  //   };

  //   confirm();
  // }, [token]);

  return (
    <div className={pageStyles.styledPage}>
      <h1>Welcome to LingoLeaf!</h1>
      <p>{message}</p>
      {confirmationSuccess === 0 && (
        <button onClick={handleConfirmClick} disabled={processing}>
          Confirm Email
        </button>
      )}
      {confirmationSuccess===1 && <NavLink to='/login'>Sign in</NavLink>}
      {confirmationSuccess===-1 && <NavLink to='/resend-confirmation'>Resend confirmation email</NavLink>}
    </div>
  );
}

export default ConfirmEmail;