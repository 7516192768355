import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import headerStyles from '../styles/component-header.module.css';
import {ReactComponent as Icon} from '../icons/icon_streak.svg';
import {ReactComponent as IconHamburger} from '../icons/icon_hamburger.svg';
import {ReactComponent as IconLogo} from '../icons/logo_icontext.svg';
import {ReactComponent as IconLogoSmall} from '../icons/logo_icononly.svg';
import {ReactComponent as IconTree} from '../icons/icon_tree_small.svg';
import { useEffect, useState } from 'react';


function StreakBox() {
  const [iconClass, setIconClass] = useState('');
  const streakState = useSelector((state) => state.streak);

  useEffect(() => {
    if(streakState.streak === 0 || streakState.pending) {
      setIconClass(`${headerStyles.headerBoxIconContainer} ${headerStyles.iconStreak} ${headerStyles.iconStreakInactive}`)
    } else {      
      setIconClass(`${headerStyles.headerBoxIconContainer} ${headerStyles.iconStreak} ${headerStyles.iconStreakActive}`)
    }
  }, [streakState])

  return (
    <NavLink to='/streak' style={{'display': 'contents'}}>
      <div className={headerStyles.headerBox}>
        <Icon className={iconClass}/>
        <div className={headerStyles.headerBoxNumberContainer}>
          <h3>{streakState.streak}</h3>
        </div>
      </div>
    </NavLink>
  );
}

function TreeCountBox() {

  const {recordsLoaded, ...treeRecords} = useSelector((state) => state.treeRecords);

  // states
  const [numComplete, setNumComplete] = useState(null);

  // get all records for completed trees
  useEffect(() => {
    if(recordsLoaded) {
      // filter for completed trees
      setNumComplete(Object.entries(treeRecords).filter(([key, treeRecord]) => treeRecord.complete).length);
      
    }
  }, [treeRecords, recordsLoaded])

  return (
    <NavLink to='/completed-trees' style={{'display': 'contents'}}>
      <div className={headerStyles.headerBox}>
        <IconTree className={`${headerStyles.headerBoxIconContainer} ${headerStyles.iconTree}`}/>
        <div className={headerStyles.headerBoxNumberContainer}>
          <h3>{numComplete}</h3>
        </div>
      </div>
    </NavLink>
  );
}

function HamburgerBox({toggleDropdown}) {
  return (
    <div className={headerStyles.hamburgerBox} onClick={toggleDropdown}>
      <IconHamburger className={headerStyles.iconHamburger}/>
    </div>
  )
}

function HeaderBar() {
  const isLoggedIn = useSelector((state) => state.user).isLoggedIn;
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  return (
    <header className={headerStyles.header}>
      <div className={headerStyles.left}>
        {isLoggedIn ? <NavLink to="/home"><IconLogo className={headerStyles.iconLogo}/></NavLink> : <NavLink to="/"><IconLogo className={headerStyles.iconLogo}/></NavLink>}
        {isLoggedIn ? <NavLink to="/home"><IconLogoSmall className={headerStyles.iconLogoSmall}/></NavLink> : <NavLink to="/"><IconLogoSmall className={headerStyles.iconLogoSmall}/></NavLink>}
      </div>
      <div className={headerStyles.middle}>
        {isLoggedIn && <TreeCountBox/>}
        {isLoggedIn && <StreakBox/>}
      </div>
      <div className={headerStyles.right}>
        {isLoggedIn && <HamburgerBox toggleDropdown={toggleDropdown} />}
        {isLoggedIn && dropdownVisible && (
          <>
            <div className={headerStyles.dropdownBackdrop} onClick={closeDropdown}></div>
            <div className={headerStyles.dropdownMenu}>
              <NavLink to="/in-progress" className={headerStyles.navLink} onClick={() => setDropdownVisible(false)}>
                Trees in Progress
              </NavLink>
              <NavLink to="/archive" className={headerStyles.navLink} onClick={() => setDropdownVisible(false)}>
                Tree Archive
              </NavLink>
              <NavLink to="/completed-trees" className={headerStyles.navLink} onClick={() => setDropdownVisible(false)}>
                Completed Trees
              </NavLink>
              <NavLink to="/settings" className={headerStyles.navLink} onClick={() => setDropdownVisible(false)}>
                Settings
              </NavLink>
              <NavLink to="/logout" className={headerStyles.navLink} onClick={() => setDropdownVisible(false)}>
                Sign Out
              </NavLink>
            </div>
          </>
        )}
      </div>
    </header>
  );
}

export default HeaderBar;
