import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import pageStyles from '../styles/page-common.module.css';
import { unscheduleUserDeletion } from '../backend';

function UnscheduleDeletion() {

  const [queryError, setQueryError] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  // if user does not have upcoming cancellation scheduled, navigate to home
  useEffect(() => {
    if (!(user.subscriptionScheduledChange && user.subscriptionScheduledChange.action === 'cancel')) {
      navigate('/home');
    }
  }, [navigate, user]);

  const handleConfirm = async () => {
    try {
      const response = await unscheduleUserDeletion(user.username);
      if(response.status === 200) {
        navigate('/unschedule-deletion-success');
      }
    } catch (error) {
      console.error('Error unscheduling deletion:', error);
      setQueryError(true)
    }
  };
  
  return (
    <div className={pageStyles.styledPage}>
      <h1>Unschedule Account Deletion</h1>
      <p>Your subscription is due to cancel on <strong>{user.subscriptionScheduledChange && user.subscriptionScheduledChange.effective_at.slice(0, 10)}</strong>, and your account will be deleted. To unschedule this cancellation, press the button below.</p>
      {queryError && <div className={pageStyles.alertBanner}> <p>An error occurred while attempting to unschedule your subscription cancellation. Please try again later.</p></div>}
      <button onClick={handleConfirm}>Unschedule deletion</button>
      <Link to="/home" style={{display: 'contents'}}><button>Return home</button></Link>
    </div>
  );
}

export default UnscheduleDeletion;