import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import pageStyles from '../styles/page-common.module.css';
import formStyles from '../styles/form-common.module.css';
import { resendConfirmation } from '../backend';


function ResendConfirmation() {
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.user).isLoggedIn;

  // shouldn't be here if logged in; navigate to home
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/home');
    }
  }, [navigate, isLoggedIn]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleConfirmEmailChange = (e) => {
    setConfirmEmail(e.target.value);
  };

  const handleResendClick = async (e) => {
    e.preventDefault();

    if (!email || !confirmEmail) {
      setMessage('Please enter your email address in both fields.');
      return;
    }

    if (email !== confirmEmail) {
      setMessage('Email addresses do not match.');
      return;
    }

    setLoading(true);
    setMessage('');

    try {
      await resendConfirmation(email)
      setMessage('Confirmation email resent successfully! Remember to check your junk/spam folder.');
    } catch (error) {
      setMessage('Failed to resend confirmation email. Maybe user does not exist, confirmation link expired or user already confirmed.');
    } finally {
      setLoading(false);
      setEmail('');
      setConfirmEmail('');
    }
  };

  return (
    <div className={pageStyles.styledPage}>
      <h1>Resend Confirmation Email</h1>
      <form onSubmit={handleResendClick} className={formStyles.styledForm}>
        <label>
          Email:
          <input
            type="email"
            name="email"
            autoComplete='email'
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter your email address"
          />
        </label>
        <label>
          Confirm email:
          <input
            type="email"
            name="confirmEmail"
            autoComplete='email'
            value={confirmEmail}
            onChange={handleConfirmEmailChange}
            placeholder="Confirm your email address"
          />
        </label>
        
        <button type="submit" disabled={loading}>
          {loading ? 'Sending...' : 'Resend Confirmation Email'}
        </button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default ResendConfirmation;