import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import pageStyles from '../styles/page-common.module.css';
import treeStyles from '../styles/page-tree.module.css';
import { stateResetTree, stateSetCurrentLanguage } from '../store';
import Loading from '../components/Loading';
import {ReactComponent as IconLeaf} from '../icons/logo_icononly.svg';
import {ReactComponent as IconTree0} from '../icons/icon_tree0.svg';
import {ReactComponent as IconTree1} from '../icons/icon_tree1.svg';
import {ReactComponent as IconTree2} from '../icons/icon_tree2.svg';
import {ReactComponent as IconTree3} from '../icons/icon_tree3.svg';
import {ReactComponent as IconTree4} from '../icons/icon_tree4.svg';
import {ReactComponent as IconTree5} from '../icons/icon_tree5.svg';
import {ReactComponent as IconTree6} from '../icons/icon_tree6.svg';
import {ReactComponent as IconTree7} from '../icons/icon_tree7.svg';
import { LEVEL_NAMES, SUPPORTED_LANGUAGES } from '../constants';
import { getTree } from '../backend';


function LeafButton({ index, tree, leaf, level, complete, score }) {
  return (
    <NavLink to='/leaf' state={{ 'tree': tree, 'leafOrder': index, 'level': level }} style={{display: "contents"}}>
      <div className={treeStyles.leafBox}>
        <div>
          <IconLeaf className={complete ? treeStyles.leafIconActive : treeStyles.leafIconInactive}/>
        </div>
        <div className={treeStyles.leafText}>
          <div className={treeStyles.leafTitle}>{index + 1}. {leaf.title}</div>
          <div>{complete ? `Leaf Collected! Score: ${score}` : ''}</div>
        </div>
      </div>
    </NavLink>
  );
}


// main page component
function Tree() {

  const user = useSelector((state) => state.user);

  // get tree from passed state
  const location = useLocation();
  const { treeMetadata, level } = location.state

  const [tree, setTree] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // on mount, set user's currentLanguage to match tree's language and download tree
  useEffect(() => {
    async function loadTree() {
      setTree(null);
      try {
        setLoading(true);
        const response = await getTree({
          username: user.username,
          week_index: treeMetadata.week_index,
          language: treeMetadata.language,
        });
        setTree(response);
        setError("");
      }
      catch(error) {
        if (error.response && error.response.status=== 404) {
          setError("Tree not found.")
        }
        else {
          setError("Some error occurred.")
        }
      }
      finally {
        setLoading(false);
      }
    }

    if (treeMetadata) {
      stateSetCurrentLanguage(treeMetadata.language);
      loadTree();
    }
  }, [treeMetadata, user])

  // get records data from redux state
  const treeRecords = useSelector((state) => state.treeRecords);

  // compose treeRecordID
  const treeRecordID = `${treeMetadata.language}_${treeMetadata.week_index}_${level}`
  
  // check if record exists
  const recordExists = treeRecords.hasOwnProperty(treeRecordID)

  // if record exists, get leaf completions and scores
  let treeComplete = false;
  const completions = Array(7).fill(false);
  const scores = Array(7).fill("");
  if (recordExists) {
    const treeRecord = treeRecords[treeRecordID]
    if (treeRecord.complete) {
      treeComplete = true;
    }
    for (let i = 0; i <= 6; i++) {
      if (treeRecord.leaves[i] && treeRecord.leaves[i].complete) {
        completions[i] = true;
        scores[i] = `${treeRecord.leaves[i].score}/5`
      }
    }
  }

  // work out current progress summary for banner and which icon to use
  let bannerContent;
  let icon;
  const levellangString = `${LEVEL_NAMES[level]} ${SUPPORTED_LANGUAGES[treeMetadata.language]} #${treeMetadata.week_index}`;
  if (recordExists) {
    const treeRecord = treeRecords[treeRecordID]
    switch(treeRecord.leavesCollected) {
      case 1:
        icon = <IconTree1 className={treeStyles.treeImage}/>;
        break;
      case 2:
        icon = <IconTree2 className={treeStyles.treeImage}/>;
        break;
      case 3:
        icon = <IconTree3 className={treeStyles.treeImage}/>;
        break;
      case 4:
        icon = <IconTree4 className={treeStyles.treeImage}/>;
        break;
      case 5:
        icon = <IconTree5 className={treeStyles.treeImage}/>;
        break;
      case 6:
        icon = <IconTree6 className={treeStyles.treeImage}/>;
        break;
      case 7:
        icon = <IconTree7 className={treeStyles.treeImage}/>;
        break;
      default:
        icon = <IconTree0 className={treeStyles.treeImage}/>;
    }
    if (treeComplete) {
      bannerContent = <><p><strong>{levellangString}</strong></p><p><strong>Tree started {treeRecord.startDate.slice(0, 10)}</strong></p><p><strong>Tree completed {treeRecord.completionDate.slice(0, 10)}</strong></p></>
    }
    else {
      bannerContent = <><p><strong>{levellangString}</strong></p><p><strong>Tree started {treeRecord.startDate.slice(0, 10)}</strong></p><p><strong>{`${treeRecord.leavesCollected}/7 leaves collected`}</strong></p></>
    }
  } else {
    bannerContent = <><p><strong>{levellangString}</strong></p><p><strong>Tree not started yet</strong></p></>
    icon = <IconTree0 className={treeStyles.treeImage}/>;
  }

  // callback for reset button
  function handleResetButton() {
    stateResetTree(treeRecordID)
  }

  // if no tree loading display error
  if (!treeMetadata) return <div className={pageStyles.styledPage}><div className={pageStyles.alertBanner}> <p>No tree found in location state.</p></div></div>

  // if page still loading return "loading" component
  if (loading) return <Loading/>;

  // if error just show error message
  if (error) return <div className={pageStyles.styledPage}><div className={pageStyles.alertBanner}> <p>Error downloading tree.</p></div></div>

  return (
    <div className={pageStyles.styledPage}>
      <h1>{tree.title}</h1>
      <div className={treeStyles.treeImageBox}>
        {icon}
      </div>
      <div className={pageStyles.alertBanner}><strong>{bannerContent}</strong></div>
      <hr className={pageStyles.hrule} />
      <h2>Leaves</h2>
      {tree.leaves.map((leaf, index) => (
        <LeafButton key={index} index={index} tree={tree} leaf={leaf} level={level} score={scores[index]} complete={completions[index]}/>
      ))}
      <hr className={pageStyles.hrule} />
      <p>The reset button below will delete all progress on this tree, such as collected leaves and used hints.</p>
      <p>It only applies on this difficulty level, and does not affect your streak.</p>
      <button onClick={handleResetButton} disabled={!recordExists}>Reset Tree</button>
    </div>
  );
}

export default Tree;