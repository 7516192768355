import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { stateSetCommPrefs } from '../store';
import pageStyles from '../styles/page-common.module.css';
import formStyles from '../styles/form-common.module.css';


function CommunicationPreferences() {
  
  // get initial vals from redux
  const { agreeMarketingComms, agreeReminders } = useSelector(state => state.user);
  
  // checkbox states
  const [marketingComms, setMarketingComms] = useState(agreeMarketingComms);
  const [reminders, setReminders] = useState(agreeReminders);

  // update local state if redux state changes externally
  useEffect(() => {
    setMarketingComms(agreeMarketingComms);
    setReminders(agreeReminders);
  }, [agreeMarketingComms, agreeReminders]);

  // handle checkbox changes
  const handleChange = (event) => {
    const { name, checked } = event.target;
    if (name === 'agreeMarketingComms') {
      setMarketingComms(checked);
    } else if (name === 'agreeReminders') {
      setReminders(checked);
    }
  };

  // handle form submission: update redux state and backend
  const handleSubmit = async (event) => {
    event.preventDefault();
    stateSetCommPrefs(marketingComms, reminders);
  };

  return (
    <div>
      <p style={{'marginBottom': '0.5rem', 'textAlign': 'left'}}>I want to receive (by email):</p>
      <form onSubmit={handleSubmit} className={formStyles.styledForm}>
        <div className={formStyles.checkboxContainer}>
          <div>
            <label>
              <input
                type="checkbox"
                name="agreeMarketingComms"
                checked={marketingComms}
                onChange={handleChange}
              />
              Information about special promotions
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                name="agreeReminders"
                checked={reminders}
                onChange={handleChange}
              />
              Study reminders
            </label>
          </div>
        </div>
        <br/>
        <button type="submit" disabled={marketingComms===agreeMarketingComms && reminders===agreeReminders}>Save Changes</button>
      </form>
    </div>
  );
};


function Settings() {

  const user = useSelector((state) => state.user);
  const [deletionScheduled, setDeletionScheduled] = useState(false);

  // on mount, check if deletion scheduled
  useEffect(() => {
    if (user.subscriptionScheduledChange && user.subscriptionScheduledChange.action === 'cancel') {
      setDeletionScheduled(true);
    }
  }, [user])

  return (
    <div className={pageStyles.styledPage}>
      <h1>Settings</h1>
      <h2>Account Credentials</h2>
      <p>Username: <strong>{user.username}</strong></p>
      <p>Email: <strong>{user.email}</strong></p>
      <p>The username linked to your account can not be changed. To change the account email address, please <NavLink to='/contact'>get in touch.</NavLink></p>
      <hr className={pageStyles.hrule}/>
      <h2>Communication Preferences</h2>
      <CommunicationPreferences/>
      <hr className={pageStyles.hrule}/>
      <h2>Account Management</h2>
      {deletionScheduled && (
        <div className={pageStyles.alertBanner}>
          <p>Your subscription is due to cancel on <strong>{user.subscriptionScheduledChange.effective_at.slice(0, 10)}</strong>. Your account will be deleted.<br/><NavLink to='/unschedule-deletion'>Unschedule deletion</NavLink></p>
        </div>
      )}
      <NavLink to="/update-payment-method" style={{display: 'contents'}}><button>Update Payment Method</button></NavLink>
      <NavLink to="/account-deletion" style={{display: 'contents'}}><button disabled={deletionScheduled}>Delete Account</button></NavLink>
    </div>
  );
}

export default Settings;