import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import { loginUser, logoutUser, setCurrentLanguage, setAgreeMarketingComms, setAgreeReminders } from './slices/userSlice';
import treeRecordsReducer from './slices/treeRecordsSlice';
import { startLeaf, hintSentence, revealQuestions, revealResults, answerQuestion, completeLeaf, completeTree, resetTree, loginTree, logoutTree } from './slices/treeRecordsSlice';
import streakReducer from './slices/streakSlice';
import { addCompletionWeek, loginStreak, logoutStreak } from './slices/streakSlice';
import { updateUserData } from './backend';
import { getWeekIndex } from './utils';


const rootReducer = combineReducers({
  user: userReducer,
  treeRecords: treeRecordsReducer,
  streak: streakReducer,
});


const store = configureStore({
    reducer: rootReducer,
});


function stateLogin(userData) {
  store.dispatch(loginUser(userData));
  store.dispatch(loginTree(userData['treeRecords']));
  store.dispatch(loginStreak({'completionWeeks': userData['completionWeeks']}))
}


function stateLogout() {
  store.dispatch(logoutUser());
  store.dispatch(logoutTree());
  store.dispatch(logoutStreak());
}


function stateSetCurrentLanguage(language) {
  store.dispatch(setCurrentLanguage(language));
  updateUserData(store.getState().user.username, { currentLanguage: language });
}


function stateSetCommPrefs(agreeMarketingComms, agreeReminders) {
  store.dispatch(setAgreeMarketingComms(agreeMarketingComms));
  store.dispatch(setAgreeReminders(agreeReminders));
  updateUserData(store.getState().user.username, { agreeMarketingComms, agreeReminders });
}

function stateStartLeaf(treeRecordID, leafOrder, numQuestions) {
  store.dispatch(startLeaf({treeRecordID, leafOrder, numQuestions}));
  const {recordsLoaded, ...treeRecords} = store.getState().treeRecords;
  updateUserData(store.getState().user.username, { treeRecords });
}


function stateHintSentence(treeRecordID, leafOrder, paragraphOrder, sentenceOrder) {
  store.dispatch(hintSentence({treeRecordID, leafOrder, paragraphOrder, sentenceOrder}));
  const {recordsLoaded, ...treeRecords} = store.getState().treeRecords;
  updateUserData(store.getState().user.username, { treeRecords });
}


function stateRevealQuestions(treeRecordID, leafOrder) {
  store.dispatch(revealQuestions({treeRecordID, leafOrder}));
  const {recordsLoaded, ...treeRecords} = store.getState().treeRecords;
  updateUserData(store.getState().user.username, { treeRecords });
}


function stateRevealResults(treeRecordID, leafOrder) {
  store.dispatch(revealResults({treeRecordID, leafOrder}));
  const {recordsLoaded, ...treeRecords} = store.getState().treeRecords;
  updateUserData(store.getState().user.username, { treeRecords });
}


function stateAnswerQuestion(treeRecordID, leafOrder, questionIndex, choiceIndex) {
  store.dispatch(answerQuestion({treeRecordID, leafOrder, questionIndex, choiceIndex}));
  const {recordsLoaded, ...treeRecords} = store.getState().treeRecords;
  updateUserData(store.getState().user.username, { treeRecords });
}


function stateCompleteLeaf(treeRecordID, leafOrder, score) {
  store.dispatch(completeLeaf({treeRecordID, leafOrder, score}));

  // if all leaves on tree complete then mark tree as complete
  const leaves = store.getState().treeRecords[treeRecordID].leaves
  const treeComplete = [0, 1, 2, 3, 4, 5, 6].every(key => leaves.hasOwnProperty(key) && leaves[key].complete);
  if (treeComplete) {
    store.dispatch(completeTree({treeRecordID}));

    const completionWeek = getWeekIndex(store.getState().treeRecords[treeRecordID].completionDate)
    store.dispatch(addCompletionWeek({completionWeek}))
  }
  const {recordsLoaded, ...treeRecords} = store.getState().treeRecords;
  const { completionWeeks } = store.getState().streak;
  updateUserData(store.getState().user.username, { treeRecords, completionWeeks });
}

function stateResetTree(treeRecordID) {
  store.dispatch(resetTree({treeRecordID}))
  const {recordsLoaded, ...treeRecords} = store.getState().treeRecords;
  updateUserData(store.getState().user.username, { treeRecords });
}

export { stateLogin, stateLogout, stateSetCurrentLanguage, stateSetCommPrefs, stateStartLeaf, stateHintSentence, stateRevealQuestions, stateRevealResults, stateAnswerQuestion, stateCompleteLeaf, stateResetTree }
export default store
