import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  recordsLoaded: false
};

const treeRecordsSlice = createSlice({
  name: 'treeRecords',
  initialState,
  reducers: {
    startLeaf: (state, action) => {
      const { treeRecordID, leafOrder, numQuestions } = action.payload;
      if (!state[treeRecordID]) {
        state[treeRecordID] = {
          complete: false,
          startDate: new Date().toISOString(),
          completionDate: null,
          leavesCollected: 0,
          leaves: {},
        };
      }
      if (!state[treeRecordID].leaves[leafOrder]) {
        state[treeRecordID].leaves[leafOrder] = {
          complete: false,
          score: null,
          questionsRevealed: false,
          resultsRevealed: false,
          hintedSentences: [],
          userAnswers: Array(numQuestions).fill(null),
        }
      }
    },
    hintSentence: (state, action) => {
      const { treeRecordID, leafOrder, paragraphOrder, sentenceOrder } = action.payload
      state[treeRecordID].leaves[leafOrder].hintedSentences.push({paragraphOrder, sentenceOrder}) 
    },
    revealQuestions: (state, action) => {
      const { treeRecordID, leafOrder } = action.payload
      state[treeRecordID].leaves[leafOrder].questionsRevealed = true
    },
    revealResults: (state, action) => {
      const { treeRecordID, leafOrder } = action.payload
      state[treeRecordID].leaves[leafOrder].resultsRevealed = true
    },
    answerQuestion: (state, action) => {
      const { treeRecordID, leafOrder, questionIndex, choiceIndex } = action.payload
      state[treeRecordID].leaves[leafOrder].userAnswers[questionIndex] = choiceIndex
    },
    completeLeaf: (state, action) => {
      const { treeRecordID, leafOrder, score } = action.payload
      state[treeRecordID].leaves[leafOrder].complete = true
      state[treeRecordID].leaves[leafOrder].score = score
      state[treeRecordID].leavesCollected = Object.keys(state[treeRecordID].leaves).filter(key => state[treeRecordID].leaves[key].complete).length;
    },
    completeTree: (state, action) => {
      const { treeRecordID } = action.payload
      state[treeRecordID].complete = true
      state[treeRecordID].completionDate = new Date().toISOString()
    },
    resetTree: (state, action) => {
      const { treeRecordID } = action.payload
      const next = {...state}
      delete next[treeRecordID]
      return next
    },
    loginTree: (state, action) => {
      return {recordsLoaded: true, ...action.payload}
    },
    logoutTree: () => initialState,
  },
});


export const { startLeaf, hintSentence, revealQuestions, revealResults, answerQuestion, completeLeaf, completeTree, resetTree, loginTree, logoutTree } = treeRecordsSlice.actions;
export default treeRecordsSlice.reducer;