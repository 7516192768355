import pageStyles from '../styles/page-common.module.css';
import { NavLink } from 'react-router-dom';


function FAQs() {
  return (
    <div className={pageStyles.styledPageLeftAligned}>
      <h1>FAQs</h1>
      
      <h2>What is LingoLeaf?</h2>
      <p>LingoLeaf is a web app you can use to help you learn a new language.</p>
      <p> Specifically, it will help you with your reading skills, by providing you with fun, engaging stories at an appropriate difficulty for your level.</p>
      <br/>
      
      <h2>How does it work?</h2>
      <p>Each story is called a 'tree', and each tree is divided into seven 'leaves' (think: chapters). Read a leaf, then answer some multiple-choice questions that will test your comprehension. Read all 7 leaves and complete all 7 quizzes to complete the tree!</p>
      <br/>

      <h2>How many trees/stories are there?</h2>
      <p>A new tree is released each week, at each language and each difficulty level. You can also browse the archive of trees from previous weeks.</p>
      <br/>

      <h2>What is my "streak" and how is it calculated?</h2>
      <p>Unlike many similar services which calculate a daily streak, on LingoLeaf you have a <strong>weekly</strong> streak. A week starts at the beginning of Monday and ends at the end of Sunday. If you collect a tree (i.e. complete all 7 leaves) at some point during the week, then your streak will increase by one. If you miss a week, your streak resets to zero. If your streak is say, 11, that means you have completed trees the last 11 weeks in a row.</p>
      <p>When you are logged in, your streak is indicated by the number next to the lightning bolt symbol in the header bar. The lightning bolt will appear grey if you have not yet completed a tree this week.</p>
      <p>In principle, you could maintain your streak by doing nothing Monday to Saturday, then hurriedly going through a whole tree on Sunday evening. However, we would recommend spacing out the 7 leaves over the week, e.g., one every day. You learn better by studying little and often!</p>
      <br/>

      <h2>Which languages can I learn?</h2>
      <p>See the <NavLink to='/languages'>Languages</NavLink> page.</p>
      <br/>

      <h2>How difficult is the reading material?</h2>
      <p>Each language on LingoLeaf has 3 difficulty levels: Beginner, Intermediate, Advanced. The aim is for <a rel="noreferrer" target="_blank" href="https://en.wikipedia.org/wiki/Common_European_Framework_of_Reference_for_Languages">CEFR</a> A1 content at the Beginner level, B1 at the Intermediate level,  C2 at the Advanced level..</p>
      <p>You can change your difficulty level in the settings menu.</p>
      <br/>

      <h2>Where does the reading material come from?</h2>
      <p>The material is all AI-generated using ChatGPT. We use a bank of carefully curated prompts and examples to ensure the generated content is novel and engaging, while remaining at the desired difficulty level.</p>

      <h2>How much does it cost?</h2>
      <p>There is a 2 week free trial, then a very small monthly subscription. See the <NavLink to='/pricing'>Pricing</NavLink> page for more details.</p>
      <br/>

      <h2>What emails and notifications will I receive from LingoLeaf, and how I update my preferences?</h2>
      <p>In accordance with our <NavLink to='/privacy'>privacy policy</NavLink>, we send three types of communications via email: essential communications (e.g., with important updates or information about your subscription), marketing communications (e.g., with information about special promotions), and study reminders. Consenting to essential communications is a requirement when signing up for an account on LingoLeaf, but the other two kinds of email are optional. There are checkboxes for these when you sign up, and you can change your settings later via the Settings interface.</p>
      <br/>

      <h2>I signed up for marketing communications / study reminders, why haven't I received any?</h2>
      <p>We haven't started sending these out yet! We're still very new. We nonetheless asked for your consent when you signed up because we plan to start sending these in the future.</p>
      <br/>

      <h2>How do I delete my account / cancel my subscription?</h2>
      <p>You can delete your account at any time (including during the free trial) within the Settings menu when logged in. Your subscription payments will not continue. You can choose to delete your account either immediately or at the end of your current subscription month.</p>
      <p>Alternatively, our payment provider (Paddle) will send you email receipts, which will contain links to cancel your subscription. Cancelling your subscription this way will also delete your LingoLeaf account.</p>
      <br/>

      <h2>Who made LingoLeaf?</h2>
      <p>See the <NavLink to='/about'>About LingoLeaf</NavLink> page.</p>
      <br/>

      <h2>What's new with LingoLeaf?</h2>
      <p>Have a look at the <NavLink to='/news'>News</NavLink> page.</p>
      <br/>

      <h2>When will you add feature X / language Y to LingoLeaf?</h2>
      <p>We're still very new and we have lots of plans for new features and languages to add. If there is something in particular you would like to see, take a look at our <NavLink to='/future'>Future Plans</NavLink> page to see if we're already planning to implement it. Otherwise, please <NavLink to='/contact'>get in touch</NavLink>!</p>
      <br/>

      <h2>Where do actual trees fit in?</h2>
      <p>By using LingoLeaf, you are <NavLink to='/charity'>planting real trees</NavLink> in the real world.</p>
      <br/>

    </div>
  );
}

export default FAQs;