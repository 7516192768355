import { fetchToken, getUserData, clearTokens } from "./backend";
import { stateLogin, stateLogout } from "./store";

async function login(username) {
  try {
    const userData = await getUserData(username);
    localStorage.setItem('username', username);
    stateLogin(userData);
  } 
  catch(error) {
    console.log(error);
    throw error;
  }
}

async function authenticate(username, password) {
  try {
    const response = await fetchToken(username, password);
    localStorage.setItem('csrf_token', response.data.csrf);
    await login(username)
  }
  catch (error) {
    console.log(error);
    throw error;
  }
}

async function logout() {
  stateLogout();
  localStorage.removeItem('username');
  localStorage.removeItem('csrf_token');
  try {
    await clearTokens();
  }
  catch (error) {
    console.log(error);
    throw error;
  }
}



export { login, authenticate, logout }